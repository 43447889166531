import axios from "axios";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Col } from "antd";

const constants = {
  isApiUrlChange: true,
  initialNextParams: { page_no: 1, selected: true },
};

export default function InfiniteScrollWrapper({
  children,
  apiUrl,
  formatter = (arr) => arr,
  height = 300,
  sortBy,
  searchKey = "name",
}) {
  const cookies = new Cookies();

  //   state to manage inifnity loop
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [nextParams, setNextParams] = useState({
    ...constants.initialNextParams,
  });

  const fetchData = async (page) => {
    const headers = { Authorization: cookies.get("rupyzToken") };

    let params = { page_no: page };
    if (sortBy === "selection") {
      if (!hasSearchValue(apiUrl, searchKey)) {
        params = nextParams;
      }
    }

    const newDataTemp = await axios.get(apiUrl, { headers, params });

    const updateParams = nextParams;
    newDataTemp?.data?.headers?.next_params?.split("&")?.forEach((pair) => {
      const [key, value] = pair.split("=");
      if (key) updateParams[key] = value;
    });
    setNextParams(updateParams);

    const data = formatter(newDataTemp.data.data);
    if (data.length < 30) {
      if (sortBy === "selection" && newDataTemp.data.headers?.next_params) {
        setPageNo((prev) => prev + 1);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(true);
    }
    return data;
  };

  useEffect(() => {
    setPageNo(-1);
    constants.isApiUrlChange = true;
  }, [apiUrl]);

  useEffect(() => {
    if (pageNo === -1) {
      constants.isApiUrlChange = false;
      if (sortBy === "selection") {
        setNextParams({ ...constants.initialNextParams });
      }
      setPageNo(1);
    } else if (!constants.isApiUrlChange) {
      fetchData(pageNo).then((newData) => {
        if (pageNo === 1) setData(newData);
        else setData(data.concat(newData));
      });
    }
  }, [pageNo]);

  return (
    <InfiniteScroll
      dataLength={data?.length}
      next={() => {
        const page = pageNo + 1 || 1;
        setPageNo(page);
      }}
      hasMore={hasMore}
      height={height}
      loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
      scrollableTarget="scrollableDiv"
    >
      <div
        id="scrollableDiv"
        style={{ display: "flex", flexDirection: "column", gap: "1em" }}
      >
        {data?.map((ele, index) => (
          <div key={data.id || index}>{children(ele, index)}</div>
        ))}
      </div>
      {!hasMore && !(data || []).length && (
        <Col align="middle">No record found</Col>
      )}
    </InfiniteScroll>
  );
}

function hasSearchValue(url, name) {
  const urlObj = new URL(url);
  const tempName = urlObj.searchParams.get(name);

  if (tempName) {
    return true;
  }
  return false;
}
