import { useEffect, useState } from "react";
import { DirectionsRenderer, DirectionsService } from "@react-google-maps/api";

const constants = {
  batchLength: 23,
};

const Directions = ({ data }) => {
  const [response, setResponse] = useState({ routes: [] });
  const [callbackPermission, setCallbackPermission] = useState(false);

  const totalWayPoints = Math.ceil(data.length / constants.batchLength) || 1;

  let combinedLegs = [],
    combinedResponse = [];

  const directionsCallback = (result, status) => {
    if (callbackPermission) {
      if (status === "OK") {
        const resLegs = result.routes[0].legs;
        combinedLegs = [...combinedLegs, ...resLegs];
        combinedResponse = [...combinedResponse, result];

        if (
          combinedLegs.length > 0 &&
          combinedLegs.length + 1 === data.length
        ) {
          const finalResponse = {
            ...combinedResponse[0],
            routes: [
              {
                legs: combinedLegs,
                overview_polyline: {
                  points: combinedLegs.reduce(
                    (acc, leg) =>
                      acc +
                      leg.steps.map((step) => step.polyline.points).join(""),
                    ""
                  ),
                },
              },
            ],
          };
          setResponse(finalResponse);
          // const totalDistance = combinedLegs.reduce(
          //   (total, leg) => total + leg.distance.value,
          //   0
          // );
          // console.log("Total distance Travelled: ", totalDistance / 1000);
        }
      } else {
        console.error(`Directions request failed due to ${status}`);
      }
      if (combinedLegs.length + 1 === data.length) setCallbackPermission(false);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      setCallbackPermission(true);
    }
  }, [data]);

  return (
    <>
      {Array(totalWayPoints)
        .fill()
        .map((_, batch_index) => {
          let batch_start = batch_index * constants.batchLength + 1;
          let batch_end = batch_start + constants.batchLength;
          batch_end = batch_end > data.length ? data.length : batch_end;

          return (
            <DirectionsService
              key={batch_index}
              options={{
                destination: data[batch_end - 1] || data[data.length - 1],
                origin: data[batch_start - 1],
                waypoints: data
                  .slice(batch_start, batch_end - 1)
                  .map((point) => ({ location: point, stopover: true })),
                travelMode: "DRIVING",
              }}
              callback={directionsCallback}
            />
          );
        })}
      {response && (
        <DirectionsRenderer
          directions={response}
          options={{
            polylineOptions: {
              strokeColor: "#322e80",
              strokeOpacity: 0.8,
              strokeWeight: 6,
            },
            suppressMarkers: true,
          }}
        />
      )}
    </>
  );
};

export default Directions;
