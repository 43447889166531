import { useState } from "react";
import Cookies from "universal-cookie";
import { Input, Modal, Radio } from "antd";
import styles from "../beatPlanList.module.css";
import { SearchOutlined } from "@ant-design/icons";
import { BASE_URL_V2, org_id } from "../../../config";
import { Staff as staffIcon } from "../../../assets/dashboardIcon";
import InfiniteScrollWrapper from "../../../components/infinite-scroll-wrapper/infiniteScrollWrapper";

export default function SelectStaff({
  open,
  value,
  onSubmit,
  setOpenChooseStaffWindow,
}) {
  const cookies = new Cookies();
  const [timer, setTimer] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedStaff, setSelectedStaff] = useState(value || {});

  const staffLoginDetails = cookies.get("rupyzLoginData") || {};

  const selfDetails = staffLoginDetails?.hierarchy
    ? {
        id: Number(staffLoginDetails?.staff_id),
        name: staffLoginDetails?.staff_name,
        user_id: Number(staffLoginDetails?.user_id),
        pic_url: staffLoginDetails?.profile_pic,
      }
    : {};

  const handleClose = () => {
    setSearchValue();
    setSelectedStaff({});
    setOpenChooseStaffWindow(false);
  };

  const debounce = (e) => {
    const value = e.target.value;
    if (timer) {
      clearTimeout(timer);
    }
    const delayDebounceFn = setTimeout(() => {
      setSearchValue(value);
    }, 700);
    setTimer(delayDebounceFn);
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      title={
        <div style={{ paddingBlock: "1em", textAlign: "center" }}>
          Assign Staff
        </div>
      }
      footer={
        <div
          className={styles.flex}
          style={{
            padding: "1.5em",
            background: "#FFFFFF",
            justifyContent: "flex-end",
            borderRadius: "0 0 10px 10px",
          }}
        >
          <div className="button_secondary" onClick={handleClose}>
            Cancel
          </div>
          <div
            className="button_primary"
            onClick={() => {
              onSubmit(selectedStaff);
              handleClose();
            }}
          >
            Save
          </div>
        </div>
      }
    >
      <div style={{ padding: "1.5em" }}>
        <Input
          size="small"
          prefix={<SearchOutlined />}
          placeholder="Search"
          onChange={debounce}
        />
        <br />
        <br />
        <div className={styles.choose_staff_container}>
          <InfiniteScrollWrapper
            apiUrl={`${BASE_URL_V2}/organization/${org_id}/staff/?dd=true&name=${searchValue}`}
            height={250}
          >
            {(item, index) => (
              <>
                {index === 0 && staffLoginDetails?.hierarchy && (
                  <Radio
                    checked={selectedStaff?.id === selfDetails?.id}
                    style={{
                      borderBottom: "1px solid #DDDDDD",
                      width: "97%",
                      padding: "0.5em 1em",
                    }}
                    onClick={() =>
                      setSelectedStaff({
                        id: selfDetails.id,
                        name: "Self",
                        user_id: selfDetails.user_id,
                      })
                    }
                  >
                    <div className={styles.flex} style={{ gap: "0.5em" }}>
                      <img
                        src={selfDetails?.pic_url || staffIcon}
                        alt={selfDetails.name}
                        className={styles.staff_img}
                      />
                      <span>{selfDetails?.name} (You)</span>
                    </div>
                  </Radio>
                )}
                <Radio
                  checked={selectedStaff?.user_id === item.user_id}
                  style={{
                    borderBottom: "1px solid #DDDDDD",
                    width: "97%",
                    padding: "0 1em 0.5em",
                    ...(index === 0 && {
                      paddingTop: "0.5em",
                    }),
                  }}
                  onClick={() =>
                    setSelectedStaff({
                      id: item.id,
                      name: item.name,
                      user_id: item.user_id,
                    })
                  }
                >
                  <div className={styles.flex} style={{ gap: "0.5em" }}>
                    <img
                      src={item?.pic_url || staffIcon}
                      alt={item.name}
                      className={styles.staff_img}
                    />
                    {item.name}
                  </div>
                </Radio>
              </>
            )}
          </InfiniteScrollWrapper>
        </div>
      </div>
    </Modal>
  );
}
