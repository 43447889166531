import Address from "./address";
import { useEffect } from "react";
import { Dropdown, Image } from "antd";
import WrapText from "../../wrapText";
import Cookies from "universal-cookie";
import styles from "./styles.module.css";
import { useNavigate } from "react-router";
import { useContext, useState } from "react";
import { ViewDetails } from "../../../assets";
import Context from "../../../context/Context";
import { MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Permissions from "../../../helpers/permissions";
import whatsappIcon from "../../../assets/whatsapp.svg";
import filterService from "../../../services/filter-service";
import { DeleteIcon, EditIcon } from "../../../assets/globle";
import { deleteCustomer } from "../../../redux/action/customerAction";
import customerIcon from "../../../assets/distributor/customer-img.svg";
import RecordActivityComponent from "../../activityModal/recordActivity";

export default function CustomerDetailCard({
  data,
  onRemove,
  showDropdown = true,
  removeButton = false,
  dropDownItems = ["VIEW_DETAILS", "EDIT", "DELETE"],
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const context = useContext(Context);
  const { setDistributorDetails, setOpenDistributorDrawer } = context;

  const [editActivityData, setEditActivityData] = useState({});

  const editCustomerPermission = Permissions("EDIT_CUSTOMER");
  const deleteCustomerPermission = Permissions("DELETE_CUSTOMER");

  const isUsedInCustomerModule = window.location.pathname === "/web/customer";

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            if (!navigateToCustomerDetails())
              filterService.setFilters({ view_detail_id: data?.id });
          }}
          className="action-dropdown-list"
        >
          <img src={ViewDetails} alt="view" />
          View Details
        </div>
      ),
    },
    dropDownItems.includes("EDIT") && editCustomerPermission
      ? {
          key: "2",
          label: (
            <div
              onClick={() => {
                navigate(`/web/customer/add-customer?id=${data?.id}`);
              }}
              className="action-dropdown-list"
            >
              <img src={EditIcon} alt="edit" /> Edit
            </div>
          ),
        }
      : {},
    dropDownItems.includes("DELETE") &&
      deleteCustomerPermission && {
        key: "3",
        label: (
          <div>
            <div
              onClick={() => {
                setDistributorDetails(data);
                dispatch(
                  deleteCustomer(data?.id, false, {
                    check_children: true,
                    is_customer_delete: true,
                  })
                );
              }}
              className="action-dropdown-list"
            >
              <img src={DeleteIcon} alt="delete" /> <span>Delete</span>
            </div>
          </div>
        ),
      },
  ];

  const navigateToCustomerDetails = () => {
    if (!isUsedInCustomerModule) {
      setOpenDistributorDrawer(true);
      navigate(`/web/customer?id=${data?.id}`);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      state.deleteCustomer.data &&
      !state.deleteCustomer.data.data.error &&
      !state?.deleteCustomer?.data?.params?.check_children
    ) {
      navigate(-1);
    }
  }, [state]);

  return (
    <>
      <div className={styles.customer_detail_card}>
        <CustomerLevelSideTag data={data} />
        <div className={styles.customer_info_card}>
          <div className={styles.customer_detail}>
            <Image
              style={{ width: 100, height: 100 }}
              className={styles.customer_profile}
              src={data?.logo_image_url || customerIcon}
              alt={data?.name}
            />

            <div className={styles.customer_basic_info}>
              <div className={styles.customer_name}>
                <div
                  style={!isUsedInCustomerModule ? { cursor: "pointer" } : {}}
                  onClick={navigateToCustomerDetails}
                >
                  <WrapText width={200}>{data?.name}</WrapText>
                </div>
                {showDropdown && (
                  <Dropdown
                    menu={{
                      items,
                    }}
                    className="action-dropdown"
                  >
                    <div className="clickable">
                      <MoreOutlined className="action-icon" />
                    </div>
                  </Dropdown>
                )}
              </div>
              <Address data={data} mapPinImg={true} />
              <div className={styles.contact_person}>
                <WrapText width={200}>{data?.contact_person_name}</WrapText>
                <a
                  href={`https://wa.me/${data?.mobile}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={whatsappIcon} alt="whatsapp" />
                </a>
              </div>
            </div>
          </div>
          {removeButton && (
            <p
              onClick={() => onRemove(true)}
              style={{
                color: "rgba(255, 0, 0, 1)",
                fontWeight: 500,
                textAlign: "center",
                paddingTop: "14px",
                cursor: "pointer",
              }}
            >
              Remove
            </p>
          )}
        </div>
      </div>
      <RecordActivityComponent
        {...{ editActivityData }}
        onClose={() => {
          setEditActivityData({});
        }}
      />
    </>
  );
}

export const getChildLevel = (details, key = "child") => {
  let level;
  if (details?.id) {
    level =
      "LEVEL-" +
      (Number(
        details?.customer_level[details?.customer_level?.length - 1] || 0
      ) +
        (key === "parent" ? -1 : 1));
  }
  if (level === "LEVEL-4") return "";
  return level;
};

export const getChildLevelName = (details, key = "child") => {
  const cookies = new Cookies();
  const customerLevelList = cookies.get("rupyzCustomerLevelConfig");
  return customerLevelList[getChildLevel(details, key)];
};

export const getCustomerCount = (details) => {
  const level = getChildLevel(details);
  return details[level?.split("-").join("_").toLowerCase() + "_customer_count"];
};

export const getParentLevel = (details) => {
  return (
    "LEVEL-" +
    (Number(details?.customer_level[details?.customer_level?.length - 1]) - 1)
  );
};

export const CustomerLevelSideTag = ({
  data,
  wordCount = 15,
  tagStyles = {},
}) => {
  const cookies = new Cookies();
  const customerLevelList = cookies.get("rupyzCustomerLevelConfig");

  const backgroundColor = {
    backgroundColor:
      data?.customer_level === "LEVEL-1"
        ? "#FDE3C9"
        : data?.customer_level === "LEVEL-2"
        ? "#DFF3CE"
        : "#D1F2FB",
  };

  const sideTagText = () => {
    if (
      data?.customer_level_name?.length > wordCount ||
      customerLevelList[data?.customer_level]?.length > wordCount
    ) {
      return (
        (data?.customer_level_name?.slice(0, wordCount) ||
          customerLevelList[data?.customer_level]?.slice(0, wordCount)) + "..."
      );
    } else
      return (
        data?.customer_level_name || customerLevelList[data?.customer_level]
      );
  };
  return (
    <div
      className={styles.side_banner}
      style={{ ...backgroundColor, ...tagStyles }}
    >
      {sideTagText()}
    </div>
  );
};
